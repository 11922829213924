<template>
  <div class="government">
    <transition name="right">
      <div class="content_right">
        <div class="backbg one">
          <div class="title">警报记录</div>
          <div class="content">
            <div class="primary boder" v-for="(item, index) of Warnlist" :key="index">
              <div>
                <div><span>警报</span> {{ item.createTime }}</div>
                <div>设备【{{ item.cameraIndexCode }}】</div>
              </div>
              <div class="s_font" @click="gettrafficstopdetail(item.recordId)">查看</div>
            </div>
          </div>
        </div>
        <div class="backbg two">
          <div class="title">监控列表</div>
          <div class="content">
              <div class="jklist" v-for="item of viklist_all" :key="item.cameraType">
                 <div>
                    <div class="s_title">{{item.cameraTypeName}}</div>
                    <div class="jkcard" :class="item2.id==act_id?'':'jkcardno'" v-for="item2 of item.cameras" :key="item2.id">
                          <div class="flex">
                              <img
                              class="gou"
                              src="../../../public/static/img/jk/gou.png"
                              alt=""
                            />
                            <div class="word">{{item2.name}}</div>
                          </div>
                   
                          <div class="select" @click="selectvik(item2.id)">
                              <img v-if="item2.id==act_id"
                                src="../../../public/static/img/jk/select.png"
                                alt=""/>
                          </div>
                    </div>
                 </div>
              </div>
          </div>
        </div>
        <div class="backbg three">
          <div class="title">网格员</div>
          <div class="content netlist">
            <div class="flex" v-for="(item, index) of wgyarr" :key="index">
              <img
                class="img"
                src="../../../public/static/img/traffic/personnel.png"
                alt=""
              />
              <div>{{ item.name }}</div>
              <div>{{ item.value }}</div>
              <div class="yuan"></div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="right">
    <div class="diong" v-if="detailshow || jkshow">
      <div class="top">
        <div class="title">{{ detailshow?'报警详情':'监控' }}</div>
        <div class="pdclose" @click="closehkvido"></div>
      </div>
      <div class="detail" v-if="detailshow">
        <div class="detail_info">
          <div>设备编码:{{ detailinfo.cameraIndexCode }}</div>
          <div>报警类型:违停</div>
          <div>报警事件:{{ detailinfo.warnTypeName }}</div>
          <div>报警时间:{{ detailinfo.createTime }}</div>
        </div>
        <img class="img" :src="detailinfo_img" @click="clickimg()" alt="" />
      </div>
      <div class="jkshow" v-if="jkshow">
          <hkvido ref="hkvido" v-if="jkshow" />
      </div>
    </div>
  </transition>
   <imageMax :img="detailinfo_img" ref="imageMax"/>
  </div>
</template>

<script>
import {
  trafficpersonlist,
  trafficstopdata,
  trafficstopdetail,
  trafficstopimage,
  hikdetail,
  hiktree,
} from "@/api/permission";
import hkvido from '@/components/hkone.vue'
import imageMax from '@/components/image_max.vue'
export default {
  name: "government",
  data() {
    return {
      detailshow: false,
      jkshow:false,
      villageId: "",
      detailinfo: {},
      detailinfo_img: "",
      Warnlist: [],  //报警
      wgyarr: [], //网格员
      viklist_all:[],
      act_id:null,
    };
  },
  components: {
    hkvido,imageMax
  },
  watch: {
    EntityData(newval) {
      if(newval.businessEntityId!=null){
        this.getdetail(newval.businessEntityId)
      }else{
        this.$message('点位未设置ID')
      }
    }
  },
  computed: {
    EntityData() {
      return this.$store.state.EntityData
    }
  },
  methods: {
    trafficlist() {
      trafficpersonlist({ villageId: this.villageId }).then((res) => {
        this.wgyarr = res;
      });
    },
    trafficdata() {
      trafficstopdata({ villageId: this.villageId }).then((res) => {
        this.Warnlist = res;
      });
    },
    gettrafficstopdetail(id) {
      this.act_id=null;
      this.detailinfo_img = "";
      this.detailinfo = {};
      trafficstopdetail({ recordId: id }).then((res) => {
        this.detailshow = true;
        this.jkshow=false;
        this.detailinfo = res;
      });
      trafficstopimage({ id: id }).then((res) => {
        this.detailinfo_img = res;
      });
    },
    getdetail(id){
      this.jkshow=true;
      this.detailshow = false;
      hikdetail({id:id}).then(res=>{
        this.$refs.hkvido.realplay(document.location.protocol=='http:'?res.videoWsUrl:res.videoWssUrl,0)
      })
    },
    getviklist(){
      hiktree({pageNum:1,pageSize:99}).then(res=>{
          this.viklist_all=res;
      })
    },
    clickimg(){
      this.$refs.imageMax.show()
    },
    selectvik(id){
      if(id==this.act_id){
        this.closehkvido()
        return
      }else{
        this.closehkvido()
        this.act_id=id;
        this.getdetail(id)
      }
      
    },
    closehkvido(){
      this.act_id=null
      if(this.jkshow){
        this.$refs.hkvido.stopAllPlay()
        this.jkshow=false
      }else{
        this.detailshow = false;
      }
    }
  },
  mounted() {
    // this.$store.commit("SET_showtype", "1669171558218,1669171569498");
    this.$store.commit("SET_showtype", "1681811014393,1681811014393,1681811014393,1681874473854,1681811065081");
    this.villageId = this.$store.state.villageId;
    this.trafficlist();
    this.trafficdata();
    this.getviklist()
  },
};
</script>
<style src="@/assets/css/contentfixed.css" scoped></style>
<style lang="scss" scoped>
.government {
  font-size: 14px;
  .content_right{
    padding-top: 4.5625rem;
  }
  .backbg {
    padding-bottom: 10px;
    
    .content {
      height: 260px;
      overflow: hidden;
      overflow-y: scroll;
    }
  }
  .one,.two,.three {
    margin-top: 10px;
    height: 320px;
  }
  .name {
    font-size: 20px;
    margin-bottom: 15px;
  }
  .boder {
    padding: 7px 12px;
    width: 100%;
    height: 80px;
    display: flex;
    margin-top: 12px;
    justify-content: space-between;
    line-height: 30px;
    font-weight: 400;
    font-size: 14px;
    span {
      font-size: 18px;
    }
    .s_font {
      font-size: 12px;
      padding-right: 10px;
      line-height: 60px;
      cursor: pointer;
    }
  }
  .primary {
    background: linear-gradient(275deg, #031c29 0%, #2b4d70 100%);
    border-left: 1px solid #a8d7e8;
    color: #a8d7e8;
  }
  .img {
    width: 24px;
    height: 24px;
  }
  .netlist {
    color: #fff;
    line-height: 27px;
    .flex div {
      margin-left: 15px;
    }
    .flex {
      padding-top: 25px;
      position: relative;
      display: flex;
      padding-bottom: 10px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      .yuan {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        position: absolute;
        bottom: -3px;
        right: -2px;
        border: 2px solid rgba(255, 255, 255, 0.5);
      }
    }
  }
  .diong {
    position: fixed;
    left: 16%;
    top: 16%;
    width: 900px;
    height: 680px;
    background: rgba(10, 42, 64, 0.9);
    border: 1px solid #064f89;
    .top {
      height: 40px;
      display: flex;
      justify-content: space-between;
      width: 100%;
      background: rgba(62, 106, 161, 0.6);
      .title {
        margin-left: 10px;
        font-size: 18px;
        color: #ffffff;
        font-weight: 600;
        line-height: 38px;
        background: linear-gradient(180deg, #ffffff 0%, #6af6fa 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .pdclose {
        width: 18px;
        height: 18px;
        cursor: pointer;
        float: right;
        position: relative;
        overflow: hidden;
        margin-top: 10px;
        opacity: 0.5;
        margin-right: 10px;
      }
    }
    .detail {
      padding: 15px;
      .detail_info {
        display: flex;
        flex-wrap: wrap;
        width: 700px;
        color: #fff;
      }
      .detail_info > div {
        margin-right: 50px;
        line-height: 35px;
      }
      .img {
        margin-top: 20px;
        width: 615px;
        height: 400px;
        border: 1px solid #ccc;
      }
    }
  }
  .jklist{
    .s_title{
      font-size: 16px;
      color: #beeeff;
      margin-top: 20px;
    }
    .jkcardno{
      opacity: 0.5;
    }
    .jkcard{
      display: flex;
      justify-content: space-between;
      height: 20px;
      margin-top: 20px;
      color: #fff;
      margin-left: 10px;
      .gou{
        margin-top: 3px;
        width: 16px;
        height: 16px;
        cursor: pointer;
      }
      .word{
        width: 200px;
        line-height: 20px;
        margin-left: 20px;
      }
      .select{
        height: 20px;
        width: 20px;
        border: 1px solid #fff;
        position: relative;
        cursor: pointer;
        img{
          width: 19px;
          height: 19px;
        }
      }
    }
  }
}
</style>
