<template>
    <transition name="right">
 <div class="image_max" :style="`background: url(${img});   background-size: 100% 100%;`"  @click="hide" v-if="imgshow">
    <div class="pdclose" ></div>
 </div>
</transition>
</template>
  <script>
 
  export default {
    name: "",
    props: {
      img: {
        type: String,
      },
    },
    watch:{
     
    },
    data() {
      return {
          imgshow:false,
      };
    },
    methods: {
      show(){
        this.imgshow=true
      },
      hide(){
        this.imgshow=false
      }
     
    },
    created(){
 
    },
  
  };
  </script>
  <style scoped lang="scss">
  .image_max{
    width: 100%;
    height: 100%;
    background: #Fff;
    position: fixed;
    top: 0px;
    z-index: 1000;
    left: 0px;
  }
  .pdclose {
    width: 18px;
    height: 18px;
    cursor: pointer;
    float: right;
    position: relative;
    overflow: hidden;
    margin-top: 10px;
    opacity: 0.5;
    margin-right: 10px;
  }
  </style>