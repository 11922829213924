<template>
  <div>
    <el-container class="player"  
      v-loading="loading"
      element-loading-text="监控正在拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)">
      <div id='player'></div>
    </el-container>
     
<!--      
     <el-button @click="realplay">开始预览</el-button>
     <el-button @click="stopPlay">停止播放</el-button> -->
  </div>
</template>
<script>
const MSE_IS_SUPPORT = !!window.MediaSource // 是否支持mse
export default {
  name: "hk",
  props: {
    viklist: {
      type: Array,
    },
  },
  watch:{
    viklist(){
      console.log(this.viklist)
    }
  },
  data() {
    return {
        player: null,
        splitNum: 1,
        mseSupport: MSE_IS_SUPPORT,
        // tabActive: MSE_IS_SUPPORT ? 'mse' : 'decoder',
        tabActive: 'decoder',
        urls: {
            realplay: 'ws://39.174.207.3:559/openUrl/I7wfcje',
            talk: 'wss://10.41.163.126:6014/proxy/10.41.163.126:559/EUrl/6gFx47S',
            playback: 'wss://10.41.163.126:6014/proxy/10.41.163.126:559/EUrl/6gFx47S'
        },
        playback: {
            startTime: '2021-07-26T00:00:00',
            endTime: '2021-07-26T23:59:59',
            valueFormat: '',
            seekStart: '2021-07-26T12:00:00',
            rate: ''
        },
        muted: true,
        volume: 50,
        volumeOnSvg: {
            template: '<svg t="1624453273744" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1421" width="200" height="200"><path d="M597.994667 138.005333q130.005333 28.010667 213.994667 132.992t84.010667 241.002667-84.010667 241.002667-213.994667 132.992l0-88q93.994667-28.010667 153.002667-106.005333t59.008-180.010667-59.008-180.010667-153.002667-106.005333l0-88zM704 512q0 120-106.005333 172.010667l0-344q106.005333 52.010667 106.005333 172.010667zM128 384l170.005333 0 213.994667-213.994667 0 684.010667-213.994667-213.994667-170.005333 0 0-256z" p-id="1422"></path></svg>'
        },
        volumeOffSvg: {
            template: '<svg t="1624453193279" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="9147" width="200" height="200"><path d="M512 170.005333l0 180.010667-90.005333-90.005333zM181.994667 128l714.005333 714.005333-53.994667 53.994667-88-88q-74.005333 58.005333-156.010667 77.994667l0-88q50.005333-13.994667 96-50.005333l-181.994667-181.994667 0 288-213.994667-213.994667-170.005333 0 0-256 202.005333 0-202.005333-202.005333zM810.005333 512q0-101.994667-59.008-180.010667t-153.002667-106.005333l0-88q130.005333 28.010667 213.994667 132.992t84.010667 241.002667q0 96-44.010667 178.005333l-64-66.005333q21.994667-53.994667 21.994667-112zM704 512q0 18.005333-2.005333 26.005333l-104-104 0-93.994667q106.005333 52.010667 106.005333 172.010667z" p-id="9148"></path></svg>'
        },
        recordStartState: 0,
        recordStartText: '录像',
        loading:false,
    };
  },
  methods: {
     init () {
     // 设置播放容器的宽高并监听窗口大小变化
     window.addEventListener('resize', () => {
       this.player.JS_Resize()
     })
   },
   createPlayer () {
     this.player = new window.JSPlugin({
       szId: 'player',
       szBasePath: "/js/",  //引入静态资源地址，我这里静态资源在public/js文件存放，所以设置为js
       iMaxSplit: 1,
       iCurrentSplit: 1,
       openDebug: true,
       oStyle: {
         borderSelect: '#FFCC00',
       }
     })
     // 事件回调绑定
     this.player.JS_SetWindowControlCallback({
       windowEventSelect: function (iWndIndex) {  //插件选中窗口回调
         console.log('windowSelect callback: ', iWndIndex);
       },
       pluginErrorHandler: function (iWndIndex, iErrorCode, oError) {  //插件错误回调
         console.log('pluginError callback: ', iWndIndex, iErrorCode, oError);
       },
      //  windowEventOver: function (iWndIndex) {  //鼠标移过回调
      //    console.log(iWndIndex);
      //  },
      //  windowEventOut: function (iWndIndex) {  //鼠标移出回调
      //    console.log(iWndIndex);
      //  },
       windowEventUp: function (iWndIndex) {  //鼠标mouseup事件回调
         console.log(iWndIndex);
       },
       windowFullCcreenChange: function (bFull) {  //全屏切换回调
         console.log('fullScreen callback: ', bFull);
       },
       firstFrameDisplay: function (iWndIndex, iWidth, iHeight) {  //首帧显示回调
         console.log('firstFrame loaded callback: ', iWndIndex, iWidth, iHeight);
       },
       performanceLack: function () {  //性能不足回调
         console.log('performanceLack callback: ');
       }
     });
   },
   arrangeWindow () {
     const splitNum = this.splitNum
     this.player.JS_ArrangeWindow(splitNum).then(
       () => { console.log(`arrangeWindow to ${splitNum}x${splitNum} success`) },
        e => { console.error(e) }
     )
   },
// 初始化结束
// 视频预览
   realplay (playURL,index,num=0) {
      console.log(playURL,index)
      // let index = this.player.currentWindowIndex,
      //     playURL = res.url,
      //     mode = 0  //解码方式：0普通模式 1高级模式
          this.loading=true
          this.player.JS_Play(playURL, { playURL, mode:0 }, index).then(
            () => {
                console.log('realplay success')
                this.loading=false
            },
            e => { 
              console.error(e);
              if(num!=2){  //第一次失败或第二次失败
               // this.$message.error(`加载第${num+1}失败，再次重新加载`)
                this.realplay(playURL,index,num+1)
              }else if(num==2){
                this.$message.error('加载失败请稍后尝试')
                this.loading=false
              }
            }
      )
   },
   // 关闭所有视频
   stopAllPlay () {
     this.player.JS_StopRealPlayAll().then(
       () => {
         this.playback.rate = 0
        // this.closeVideoTree()
       },
       e => { console.error(e) }
     )
   },
   // 关闭单个视频
   stopPlay () {
     this.player.JS_Stop(1).then(
       () => {
         this.playback.rate = 0
         console.log('stop realplay success')
         // this.closeVideoTree()
         const index = this.player.currentWindowIndex
           this.selectAisle(this.videoList[index], index)
         },

         e => { console.error(e) }
       )
      }
  },
  created(){
    let _this=this
    setTimeout(function () {
     _this.init()
     _this.createPlayer()
     _this.arrangeWindow()
   })
  },
  destroyed(){
    this.player=null
  }
};
</script>
<style scoped lang="scss">
.player{
  padding: 20px;
  height: 640px;
  width: 900px;
}
#player{
  
    height: 600px;
    width: 860px;
    position: absolute;
}
</style>